// React
import { useEffect as onLoad } from 'react';
import { useStateCallback as state, useEffectExceptOnMount as onChange } from './Hooks.js';

// Libraries
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Img } from 'react-image';
import 'animate.css';

// Components
import ImageViewer from './ImageViewer.js';

// ______________ HOME ______________________
import ArrowLeft from './images/arrowleft.svg';
import ArrowRight from './images/arrowright.svg';
import Swipe from './images/swipe.svg';
import Malte from './images/Malte2.jpg'

// ______________ UNI ____________________
import TOR from './TOR.pdf';

// ______________ CERTIFICATES _______________
import IMGcspo from './zertifikate/cspo.jpg';
import IMGcpre from './zertifikate/cpre.jpg';
import IMGmpirics from './zertifikate/mpirics.jpg';
import IMGqa from './zertifikate/QualityDojo.jpg';
import IMGureact from './zertifikate/udemy_react.jpg';
import IMGularavel from './zertifikate/udemy_laravel.jpg';
import IMGucomponents from './zertifikate/udemy_webcomp.png';
import IMGujs from './zertifikate/udemy_js.jpg';
import IMGbikablo from './zertifikate/Bikablo Basics.jpg';
import IMGuniecc from './zertifikate/Enterprise Collaboration Challenge.jpg';
import IMGunierp from './zertifikate/ERP Challenge.jpg';
import IMGLinkedFrontend from './zertifikate/LinkedIn-Frontend.jpg';
import IMGLinkedHTML from './zertifikate/LinkedIn-HTML.jpg';

// _______________ PROJECT: CDP _________________
import IMGCGM from './images/CGM.png'
import IMGCDP01 from './projekte/CDP_01.jpg';
import IMGCDP02 from './projekte/CDP_02.jpg';
import IMGCDP03 from './projekte/CDP_03.jpg';
import IMGCDP04 from './projekte/CDP_04.jpg';
/*import IMGCGM_User_Story_01 from './projekte/CGM_Story_01.jpg';
import IMGCGM_User_Story_02 from './projekte/CGM_Story_02.jpg';
import IMGCGM_User_Story_03 from './projekte/CGM_Story_03.jpg';
import IMGCGM_User_Story_04 from './projekte/CGM_Story_04.jpg';
import IMGCGM_User_Story_05 from './projekte/CGM_Story_05.jpg';*/

// _______________ PROJECT: KOSMEDA _________________
import IMGkosmeda from './projekte/kosmeda.jpg';

// _______________ PROJECT: WEBEDIA _________________
import IMGWebedia from './images/Webedia.png';
import IMGGS from './projekte/GS_Teufel.jpg';
import IMGGS2 from './projekte/GS_Tarife.jpg';
//import IMGwebedia from './arbeitszeugnisse/webedia.jpg';

// _______________ PROJECT: DIGITAL LIBRARY FOR ERNI _________________
import IMGErni from './images/erni.png';
import IMGDigLib01 from './projekte/Digital_Library_01.PNG';
import IMGDigLib02 from './projekte/Digital_Library_02.PNG';

// _______________ PROJECT: EGRADE _________________
import IMGeGrade1 from './projekte/eGrade1.jpg';
import IMGeGrade2 from './projekte/eGrade2.jpg';
import IMGeGrade3 from './projekte/eGrade3.jpg';

// _______________ PORFTOLIO & MISC _________________
import IMGneuwiednet from './misc/neuwied-net.jpg';
import IMGTBA from './misc/tba.jpg';
import IMGumfrage from './misc/umfrage.jpg';
import IMGGameStar from './misc/gamestar.jpg';
import IMG1aspace from './misc/1aspace.jpg';
import IMGreplaying from './misc/replaying.jpg';
import IMGplattenspiel from './misc/Plattenspiel-Media.jpg';
import IMGffstudios from './misc/FF-Studios.png';
import IMGcargraphy from './misc/Cargraphy.jpg';
import IMGfarcry from './misc/Far-Cry-6-Teaser-Neu-GameStar.jpg';
import IMGfusspflege from './misc/fusspflege.jpg';

export default function Content(props) {

    let content = "";
    let durTooltip = 400;

    const [openModal, setOpenModal] = state(false);
    const closeModal = () => {
        setOpenModal(false);
    }
    const [modalSource, setModalSource] = state("");

    const imageViewer = (e) => {
        setModalSource(e.target.src);
        setOpenModal(true);
    }

    const getHour = () => {
        const date = new Date();
        const hour = date.getHours();
        return hour;
    }

    if (props.load === 'home') content =
        <>
            <div className="content-home">
                <div>
                    <img src={Malte} className='img-profile-home' />
                </div>

                <div>
                    <h1>Good day, thank you for your interest!</h1>
                    <p>
                        I'm Malte, 28 years old. Currently occupied as an IT Consultant.
                        And also for my further way is certain: A conceptual, creative, success-oriented contribution to modern software is exactly what I intend to do, since that's what my enthusiasm and interest is for.
                    </p>
                    <p>
                        In my spare time I like to deal with software development and side projects, for which a basic understanding can be incorporated into daily work.
                    </p>
                    <p>
                        Use your keyboard <img className='img-arrow' src={ArrowLeft} /> <img className='img-arrow' src={ArrowRight} /> to navigate through this website.
                    </p>
                    <p>
                        If you are using a mobile device, just use common swipe gestures! <img className='img-arrow' src={Swipe} />
                    </p>
                </div>
            </div>
        </>
    if (props.load === 'resume') content =
        <>
            <div className="content-cv">
                <div className="work-experience">
                    <h2>
                        Work Experience
                    </h2>
                    <div className="flex">
                        <div className="box">
                            <div className="title">ERNI</div>
                            <div className="info">Professional Consultant</div>
                            <div className="description">
                                Swiss Software Engineering.
                            </div>
                            <div className="date">June 2022 until today. </div>
                        </div>
                        <div className="box">
                            <div className="title">CompuGroup Medical SE & Co. KGaA</div>
                            <div className="info">Product Owner</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        <u>Tasks:</u> Requirements engineering, Task distribution, Capacity planning, Release planning, Sprint planning, Sprint retros, Sprint reviews
                                    </li>
                                    <li>
                                        <u>Project:</u> Physician information system „CLICKDOC pro V3“ based on Angular, node.js and Java, which is developed agilely according to Scrum (SAFe).
                                    </li>
                                    <li>
                                        <u>Responsibility:</u> Value Stream with nine colleagues, Frontend development & UI
                                    </li>
                                    <li>
                                        <u>Team:</u> International team with ~ 50 direct colleagues
                                    </li>
                                    <li>
                                        <u>Tools:</u> Confluence, Git, Jira, Jenkins, Miro, Productboard, Office 365, Portainer, VS Code
                                    </li>
                                </ul>
                            </div>
                            <div className="date">May 2021 until May 2022. </div>
                        </div>
                        <div className="box">
                            <div className="title">CompuGroup Medical SE & Co. KGaA</div>
                            <div className="info">Working Student: Product Owner</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        <u>Tasks:</u> User Stories, with a focus on UI/UX. Abstraction of user testing in practices and mockups from „Sketch“ into technical HTML/CSS code and requirements.
                                    </li>
                                    <li>
                                        <u>Project:</u> AIS „CLICKDOC pro V3“
                                    </li>
                                </ul>
                            </div>
                            <div className="date">September 2020 until April 2021.</div>
                        </div>
                        <div className="box">
                            <div className="title">CompuGroup Medical SE & Co. KGaA</div>
                            <div className="info">Working Student: Software Quality Assurance</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        <u>Tasks:</u> Bug Reporting, Manual Test Cases.
                                    </li>
                                    <li>
                                        <u>Project:</u> AIS „CLICKDOC pro V3“
                                    </li>
                                </ul>
                            </div>
                            <div className="date">August 2019 until August 2020.</div>
                        </div>
                        <div className="box">
                            <div className="title">Webedia Gaming GmbH</div>
                            <div className="info">Affiliate Marketing</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        Webedia is a pan-European publisher of leading entertainment brands such as GameStar and Moviepilots.
                                    </li>
                                    <li>
                                        <u>Tasks:</u> Content Management, E-commerce Marketing, Google Analytics & Trends, Search Engine Optimization
                                    </li>
                                </ul>
                            </div>
                            <div className="date">March 2018 until April 2021.</div>
                        </div>
                        <div className="box">
                            <div className="title">Center For Enterprise Information Research</div>
                            <div className="info">Student Assistant</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        <u>Tasks:</u> Assistance in the field of IT-supported business development and digitization of workplaces.
                                    </li>
                                    <li>
                                        <u>Project:</u> Migration from Notes to SQL databases.
                                    </li>
                                    <li>
                                        <u>Tools:</u> IBM Connections (ECS), IBM Notes (CRM)
                                    </li>
                                </ul>
                            </div>
                            <div className="date">November 2017 until January 2019.</div>
                        </div>
                        <div className="box">
                            <div className="title">University of Koblenz-Landau</div>
                            <div className="info">Technical Assistant at the Institute for Pedagogy</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        Course setup in OLAT, first level support
                                    </li>
                                    <li>
                                        Redesign of the website and forms (Plone)
                                    </li>
                                </ul>
                            </div>
                            <div className="date">April 2017 until March 2019.</div>
                        </div>
                        <div className="box">
                            <div className="title">Elementary School in Neuwied</div>
                            <div className="info">Application Support</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        Setup/configuration of new systems and LAN.
                                    </li>
                                </ul>
                            </div>
                            <div className="date">August 2015 until August 2016</div>
                        </div>
                    </div>
                </div>
                <div className="side-project">
                    <h2>
                        Side Businesses
                    </h2>
                    <div className="flex">
                        <div className="box">
                            <div className="title">kosmeda</div>
                            <div className="info">Android/iOS/Web App for podiatrists</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        Founded as a GbR together with a friend, we are continuously developing an application for podiatrists.
                                    </li>
                                    <li>
                                        <Link to="/project-kosmeda" rel="noreferrer">Elevator pitch</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="date">2022</div>
                        </div>
                        <div className="box">
                            <div className="title">eGrade</div>
                            <div className="info">Cloud-based „School Management Software“</div>
                            <div className="description">
                                <ul>
                                    <li>
                                        Developed with HTML, CSS, JS, Twig, PHP.
                                    </li>
                                    <li>
                                        The concept was recorded in an accelerator program for potential start-ups at „gruendungskultur.de“.
                                    </li>
                                    <li>
                                        <a href="https://webjects.de/eGrade.pdf" target="_blank" rel="noreferrer">eGrade Presentation.pdf</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="date">2020</div>
                        </div>
                    </div>
                </div>
                <div className="education">
                    <h2>
                        Education
                    </h2>
                    <div className="flex">
                        <div className="box">
                            <div className="title">University of Koblenz-Landau</div>
                            <div className="info">Business Informatics</div>
                            <div className="date">March 2016 until April 2021</div>
                        </div>
                        <div className="box">
                            <div className="title">Ludwig-Erhard-Schule Neuwied</div>
                            <div className="info">A-Level with 1,9</div>
                            <div className="description">Advanced courses: Economics, Informatics and English</div>
                            <div className="date">September 2012 until July 2015</div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    if (props.load === 'certificates') content =
        <>
            <div className="content-certificates">
                <h2>
                    Courses &amp; Certificates
                </h2>
                <blockquote>
                    “Theory and practice sometimes clash. And when that happens, theory loses. Every single time.” – <cite>Linus Torvalds</cite>
                </blockquote>
                <div className="column-3">
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Certified Requirements Engineer">
                        <span><Img alt="Certified Requirements Engineer" src={IMGcpre} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Certified Scrum Product Owner">
                        <span><Img alt="Certified Scrum Product Owner" src={IMGcspo} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="MPIRICS Scrum Foundations">
                        <span><Img alt="MPIRICS Scrum Foundations" src={IMGmpirics} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Udemy: React.js">
                        <span><Img alt="Udemy: React.js" src={IMGureact} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Udemy: Laravel & vue.js">
                        <span><Img alt="Udemy: Laravel" src={IMGularavel} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Udemy: Web Components with Stencil.js">
                        <span><Img alt="Udemy: Web Components with Stencil.js" src={IMGucomponents} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="ISTQB Certified Tester">
                        <span><Img alt="ISTQB Certified Tester" src={IMGqa} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Udemy: JavaScript">
                        <span><Img alt="Udemy: JavaScript" src={IMGujs} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="LinkedIn: Frontend">
                        <span><Img alt="LinkedIn: Frontend" src={IMGLinkedFrontend} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="LinkedIn: HTML">
                        <span><Img alt="LinkedIn: HTML" src={IMGLinkedHTML} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="bikablo Basics">
                        <span><Img alt="bikablo Basics" src={IMGbikablo} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="ERP Challenge 2018">
                        <span><Img alt="ERP Challenge 2018" src={IMGunierp} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                    <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Enterprise Collaboration Challenge">
                        <span><Img alt="Enterprise Collaboration Challenge" src={IMGuniecc} className="img-clickable" onClick={(e) => imageViewer(e)} /></span>
                    </Tooltip>
                </div>
            </div>
        </>

    if (props.load === 'project-digital-library') content =
        <>
            <h2>
                Project: Digital Library
            </h2>
            <h3>
                <b>Full Stack Development</b> &middot; ERNI Deutschland GmbH &middot; 2022
            </h3>
            <img src={IMGErni} class="company-logo" />
            <div className="column-2">
                <p>
                    Within this small, internal project, a digital library was to be created for ERNI Germany in order to record specialist books at the respective locations.
                    Instead of a tedious Excel or SharePoint list, I developed a small web app with EAN scanning functionality.
                </p>
                <p>
                    The app has been integrated with Azure, to access internal company user data. Furthermore, the app includes a connection to SharePoint, in order to integrate an e-book library.
                </p>
                <p>
                    The library also has a lending system that notifies the Office Manager when a delivery request is made to another location or to the user.
                </p>
                <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="ERNI Digital Library">
                    <span><Img alt="ERNI Digital Library" className="img-clickable" src={IMGDigLib01} onClick={(e) => imageViewer(e)} /></span>
                </Tooltip>
                <p>
                    <b>Tasks:</b> Full Stack Development
                </p>
                <p>
                    <b>Technologies:</b> React, node.js, MSAL.js, MongoDB, Azure
                </p>
                <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="ERNI Digital Library">
                    <span><Img alt="ERNI Digital Library" className="img-clickable smartphone" src={IMGDigLib02} onClick={(e) => imageViewer(e)} /></span>
                </Tooltip>
            </div>
        </>

    if (props.load === 'project-kosmeda') content =
        <>
            <h2>
                Project: kosmeda
            </h2>
            <h3>
                <b>Co-Founder &amp; Full Stack Development</b> &middot; kosmeda GbR &middot; 2022
            </h3>
            <div className="column-2">
                <p>
                    Have you ever thought about contributing in the branch of podiatrists, cosmeticians, or daily assistants? Probably not. Statistically, however, 50% of all people over 50 use those services at least occasionally. And it is precisely the self-employed in these industries who often have to rely on paper and pencil, or overpriced and outdated software products for large practices.
                </p>
                <p>
                    A clientele that is still growing a bit into the digital age.
                </p>
                <p>
                    From this thought, <b>kosmeda</b> came into being as a “bootstrapped sidepreneur”.
                </p>
                <p>
                    The kosmeda App unites business and customers in this bigger niche branch. The software allows self-employed to organize patients,
                    appointments, trips and finances,
                    with connection to the popular SumUp terminal to make payments immediately at the customer's location.
                    And of course much more.
                </p>
                <p>
                    Furthermore, a major task is to research and develop the app in accordance with German financial law, e.g. for invoicing or vouchering, to achieve GoBD compliance.
                </p>
                <p>
                    Together with a consulting start-up expert from the WHU foundation we estimated the Total Addressable Market at 5 Million €, only taking podiatrists into account, by a low competition level.
                </p>
                <p>
                    The app is currently in the later stages of development. We currently have over 70 active testers, who actually work full- or part-time in the field of podiatry.
                </p>
                <p>
                    Feel free to have a look! <a href="https://play.google.com/store/apps/details?id=de.webjects.kosmeda" target="_blank">Google Play Store</a> or <a href="https://apps.apple.com/de/app/kosmeda/id1624710159?platform=iphone" target="_blank">Apple App Store</a>.
                </p>
                <p>
                    <b>Tasks:</b> Frontend development, Backend development, Server management
                </p>
                <p>
                    <b>Technologies:</b> React, node.js, MongoDB, Ionic (Capacitor), Debian
                </p>
                <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Developing kosmeda ...">
                    <span><Img alt="Letter of Recommendation" className="img-clickable" src={IMGkosmeda} onClick={(e) => imageViewer(e)} /></span>
                </Tooltip>
            </div>
        </>

    if (props.load === 'webedia') content =
        <>
            <h2>
                Affiliate Marketing
            </h2>
            <h3>
                <b>Content Creator</b> &middot; Webedia Group Deutschland &middot; from 2018 until 2021
            </h3>
            <img src={IMGWebedia} class="company-logo" />
            <div className="column-2">
                <p>
                    At Webedia I was able to give free rein to my editorial streak here.
                    I learned a lot about editorial writing, research and basic methods of marketing.
                    The remote team has received frequent internal training.
                </p>
                <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Go to this article on gamestar.de">
                    <a target="_blank" href="https://www.gamestar.de/artikel/teufel-concept-e450-fuer-499-ultima-40-mk2-fuer-299-black-deals-auf-teufelde-mit-bis-zu-40-rabatt,3336908.html">
                        <Img alt="Affiliate Marketing" className="img-clickable" src={IMGGS} />
                    </a>
                </Tooltip>
                <p>
                    I got to write small marketing and also larger sponsored stories. For example, for Teufel Lautsprecher GmbH.
                    The deals section was freshly initiated at this time, and is still present today.
                </p>
                <Tooltip followCursor enterDelay={durTooltip} enterNextDelay={durTooltip} title="Go to this article on gamestar.de">
                    <a target="_blank" href="https://www.gamestar.de/artikel/kaufberatung-handytarife-gaming,3366748.html">
                        <Img alt="Affiliate Marketing" className="img-clickable" src={IMGGS2} />
                    </a>
                </Tooltip>
            </div>
        </>

    if (props.load === 'project-cdp') content =
        <>
            <h2>
                Project: Clickdoc Pro
            </h2>
            <h3>
                <b>Product Owner</b> &middot; CompuGroup Medical SE & Co. KGaA &middot;  from 2019 until 2022
            </h3>
            <img src={IMGCGM} class="company-logo" />
            <div className="column-2">
                <p>
                    <b>CLICKDOCpro</b> is a sophisticated physician information system based on latest web technologies.
                    The system is connected to a B2C derivative through which patients can book consultation or video hours.
                    Practices can also be linked to each other, for example, to refer patients to a specialist or manage multi-org units.
                </p>
                <p>
                    The commonly named »World of CLICKDOC« is expected to inherit all the functionalities of various physician information systems that will have reached the end of their life cycle in the future.
                    Thus, it is considered to be the company's most important business unit.
                </p>
                <p>
                    Take a look! <a href="https://clickdoc.de" target="_blank">CLICKDOC.de</a>
                </p>
                <Img alt="ClickDoc" className="img-clickable" src={IMGCDP01} onClick={(e) => imageViewer(e)} />
                <Img alt="ClickDoc" className="img-clickable" src={IMGCDP02} onClick={(e) => imageViewer(e)} />
                <Img alt="ClickDoc" className="img-clickable" src={IMGCDP03} onClick={(e) => imageViewer(e)} />
                <p>
                    I worked on this platform as a <i>Product Owner</i> together with over 50 international colleagues and several teams.
                    A major challenge was to align features between all teams due to the many dependencies inherent in the extensive architecture of this application.
                </p>
                <p>
                    With goal-driven responsibility for nine developers in a Value Stream, I accomplished common Scrum tasks and further advanced, such as budgeting, capacity planning, and participation in interviews.
                </p>
                <p>
                    Since we didn't have a Scrum Master in this project, I also took on tasks around team management, such as collecting internal satisfaction surveys or discussing developers' requests. On the other hand, some of the stakeholder contact that the product architect was responsible accordingly did not fall into my hands.
                </p>
                <Img alt="ClickDoc" className="img-clickable" src={IMGCDP04} onClick={(e) => imageViewer(e)} />
            </div>
            {/*
            <h2>
                Work Sample
            </h2>
            <div className="flex flex-50">
                <Img alt="User Story" className="img-clickable" src={IMGCGM_User_Story_01} onClick={(e) => imageViewer(e)} />
                <Img alt="User Story" className="img-clickable" src={IMGCGM_User_Story_02} onClick={(e) => imageViewer(e)} />
                <Img alt="User Story" className="img-clickable" src={IMGCGM_User_Story_03} onClick={(e) => imageViewer(e)} />
                <Img alt="User Story" className="img-clickable" src={IMGCGM_User_Story_04} onClick={(e) => imageViewer(e)} />
                <Img alt="User Story" className="img-clickable" src={IMGCGM_User_Story_05} onClick={(e) => imageViewer(e)} />
            </div>
            */}
        </>

    return (
        <>
            <ImageViewer open={openModal} onClose={closeModal} src={modalSource} />
            {content}
        </>
    )
}


