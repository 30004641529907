// React
import React from 'react';
import { useStateCallback as state, useEffectExceptOnMount as onChange } from './Hooks.js';

// Libraries

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import 'animate.css';

// Images

import malte from './images/Malte.jpg';
import FlagDE from './images/DE.png';
import FlagEN from './images/EN.png';
import FlagFR from './images/FR.png';
import FlagES from './images/ES.png';
import LinearProgress from '@mui/material/LinearProgress';

import SkillAndroid from './images/android.svg';
import SkillIndesign from './images/indesign.svg';
import SkillPhotoshop from './images/photoshop.svg';
import SkillHTML from './images/html.svg';
import SkillCSS from './images/css.svg';
import SkillJS from './images/javascript.svg';
import SkillMongoDB from './images/mongodb.svg';
import SkillNodeJS from './images/nodejs.svg';
import SkillReact from './images/react.svg';
import SkillPHP from './images/php.svg';
import SkillMySQL from './images/mysql.svg';

import AboutMail from './images/new_mail.png';
import AboutGit from './images/new_git.png';
import AboutLinkedIn from './images/new_linkedin.png';
import AboutWhatsApp from './images/new_whatsapp.png';
import AboutXing from './images/new_xing.png';



export default function Sidebar() {

    const [open, setOpen] = state(false);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const copyToClipboard = (e) => {
        setOpen(true);
        navigator.clipboard.writeText(e.target.name);
    };


    const handleClose = (event, reason) => {
        setOpen(false);
    }


    return (

        <>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Phone number copied to your clipboard!
                </Alert>
            </Snackbar>

            <div className="sidebar">
                <Tooltip followCursor title="Me!">
                    <div className="sidebar-photo">
                        <img alt="Malte" src={malte} />
                    </div>
                </Tooltip>
                <h1>Malte Hoch</h1>
                <div className="sidebar-section">
                    <div className="sidebar-section-social">
                        <Tooltip title="LinkedIn">
                            <a href="https://www.linkedin.com/in/malte-hoch-aa402915a/" target="_blank" rel="noreferrer"><img className="mb-10" alt="LinkedIn" src={AboutLinkedIn} /></a>
                        </Tooltip>
                        <Tooltip title="Xing">
                            <a href="https://www.xing.com/profile/Malte_Hoch/cv" target="_blank" rel="noreferrer">
                                <img alt="Xing" className="mb-10" src={AboutXing} />
                            </a>
                        </Tooltip>
                        <Tooltip title="GitHub">
                            <a href="https://github.com/malte94" target="_blank" rel="noreferrer">
                                <img alt="GitHub" className="mb-10" src={AboutGit} />
                            </a>
                        </Tooltip>
                        <Tooltip title="mhoch94@gmail.com">
                            <a href="mailto:mhoch94@gmail.com">
                                <img alt="Mail" className="mb-10" src={AboutMail} />
                            </a>
                        </Tooltip>
                    </div>
                    <h2>Languages</h2>
                    <div className="sidebar-section-languages flex-80-each">
                        <img alt="DE" src={FlagDE} />
                        <LinearProgress variant="determinate" value={100} />
                        <img alt="EN" src={FlagEN} />
                        <LinearProgress variant="determinate" value={75} />
                        <img alt="ES" src={FlagES} />
                        <LinearProgress variant="determinate" value={35} />
                        <img alt="FR" src={FlagFR} />
                        <LinearProgress variant="determinate" value={15} />
                    </div>
                    <h2>Tech-Stack</h2>
                    <div className="sidebar-section-skills">
                        <Tooltip title="Adobe Photoshop">
                            <img alt="Adobe Photoshop" src={SkillPhotoshop} />
                        </Tooltip>
                        <Tooltip title="Adobe Indesign">
                            <img alt="Adobe Indesign" src={SkillIndesign} />
                        </Tooltip>
                        <Tooltip title="HTML">
                            <img alt="HTML" src={SkillHTML} />
                        </Tooltip>
                        <Tooltip title="CSS">
                            <img alt="HTML" src={SkillCSS} />
                        </Tooltip>
                        <Tooltip title="JavaScript">
                            <img alt="JavaScript" src={SkillJS} />
                        </Tooltip>
                        <Tooltip title="React.js">
                            <img alt="React.js" src={SkillReact} />
                        </Tooltip>
                        <Tooltip title="node.js (Express)">
                            <img alt="node.js (Express)" src={SkillNodeJS} />
                        </Tooltip>
                        <Tooltip title="PHP">
                            <img alt="PHP" src={SkillPHP} />
                        </Tooltip>
                        <Tooltip title="MongoDB">
                            <img alt="MongoDB" src={SkillMongoDB} />
                        </Tooltip>
                        <Tooltip title="MySQL">
                            <img alt="MySQL" src={SkillMySQL} />
                        </Tooltip>
                    </div>
                </div>
            </div>

        </>
    );
}
