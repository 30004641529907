import Modal from '@mui/material/Modal';


export default function ImageViewer(props) {

    return(
        <>
            <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <div>
                    <img src={props.src} onClick={() => props.onClose()} />
                </div>
            </Modal>
        </>
    )}