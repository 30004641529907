// React
import React from 'react'
import { useStateCallback as state, useEffectExceptOnMount as onChange } from './Hooks.js';

// CSS
import './App.css';

// Components
import Sidebar from './Sidebar.js';
import Content from './Content.js';

// Libraries
import Pagination from '@mui/material/Pagination';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSwipeable } from 'react-swipeable';

// Static
import CV from './CV Malte Hoch.pdf';

export default function App() {

  const navigateTo = useNavigate();
  const currentURL = useLocation();
  const [currentPage, setCurrentPage] = state(1);
  const [quotes, setQuotes] = state(["fetching ..."]);

  const swipe = useSwipeable({
    onSwipedLeft: () => setCurrentPage(currentPage => currentPage + 1),
    onSwipedRight: () => setCurrentPage(currentPage => currentPage - 1)
  });

  const checkKey = (event) => {
    if (event.keyCode == '37') {
      setCurrentPage(currentPage => currentPage - 1);
    } else if (event.keyCode == '39') {
      setCurrentPage(currentPage => currentPage + 1);
    }
  }

  React.useEffect(() => {

    document.addEventListener("keydown", checkKey, true);
    if (currentURL.pathname.match("/resume")) {
      setCurrentPage(2);
    }
    if (currentURL.pathname.match("/certificates")) {
      setCurrentPage(3);
    }
    if (currentURL.pathname.match("/project-digital-library")) {
      setCurrentPage(4);
    }
    if (currentURL.pathname.match("/project-cdp")) {
      setCurrentPage(5);
    }
    if (currentURL.pathname.match("/project-kosmeda")) {
      setCurrentPage(6);
    }
    if (currentURL.pathname.match("/webedia")) {
      setCurrentPage(7);
    }
    if (currentURL.pathname.match("/project-egrade")) {
      setCurrentPage(8);
    }

    // Quote of the Day

    fetch('https://quotes.rest/qod?language=en').then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
      .then((data) => {
        setQuotes(data.contents.quotes[0].quote)
      })
      .catch((error) => {
        setQuotes("Don't let the fear of losing be greater than the excitement of winning.")
      });

    console.log("Component loaded ...");

  }, [])

  React.useEffect(() => {
    switch (currentPage) {
      case 0: setCurrentPage(1); // Min
        break;
      case 1: navigateTo('/')
        break;
      case 2: navigateTo('/resume');
        break;
      case 3: navigateTo('/certificates');
        break;
      case 4: navigateTo('/project-digital-library');
        break;
      case 5: navigateTo('/project-cdp');
        break;
      case 6: navigateTo('/project-kosmeda');
        break;
      case 7: navigateTo('/webedia');
        break;
      case 8: setCurrentPage(7); // Max
    }

    console.log("New page is: " + currentPage);

    window.scroll(0, 0);
    document.body.scrollTop = 0;
    document.querySelector('.content').scrollTop = 0;

  }, [currentPage])

  return (
    <div className="App">
      <Sidebar />
      <div className="content" {...swipe}>
        <Routes>
          <Route exact path="/" element={<Content load="home" quote={quotes} />} />
          <Route exact path="/resume" element={<Content load="resume" />} />
          <Route exact path="/certificates" element={<Content load="certificates" />} />
          <Route exact path="/project-digital-library" element={<Content load="project-digital-library" />} />
          <Route exact path="/project-cdp" element={<Content load="project-cdp" />} />
          <Route exact path="/webedia" element={<Content load="webedia" />} />
          <Route exact path="/project-kosmeda" element={<Content load="project-kosmeda" />} />
          <Route exact path="/portfolio" element={<Content load="portfolio" />} />
        </Routes>
      </div>
      <div className="nav">
        <div className="btn-primary" style={{ visibility: 'hidden' }}><a href={CV} target="_blank" rel="noreferrer">Download CV (.pdf)</a></div>
        <Pagination count={7} page={currentPage} siblingCount={1} onChange={(event, value) => { setCurrentPage(value) }} />
        <div className="btn-secondary" style={{ visibility: 'hidden' }}>Table of Contents</div>
      </div>

    </div>
  );
}